import React, { useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import IndexLayout from '../layouts'
import IAktuellesArtikel from '../interfaces/IAktuellesArtikel'
import Categories from '../enums/Categories'
import AktuellesArtikels from '../components/AktuellesArtikels'
import SearchInput from '../components/SearchInput'

type AktuellesQueryProps = {
  allContentfulAktuellesArtikel: {
    edges: Array<IAktuellesArtikel>
  }
}
type AktuellesPageProps = PageProps<AktuellesQueryProps>

export default function Aktuelles(props: AktuellesPageProps) {
  const { data } = props
  const [activeSection, setActiveSection] = useState<Categories>(Categories.BLOG)
  const [searchQuery, setSearchQuery] = useState<string>('')
  return (
    <IndexLayout>
      <h1 className="sectionTitle">Aktuelles</h1>
      <SearchInput onChangeSearchQuery={x => setSearchQuery(x)} />
      {searchQuery === '' && (
        <>
          <div className="aktuelles__flexbox">
            <div>
              <h2>
                <button
                  type="button"
                  className={activeSection === Categories.BLOG ? 'active' : ''}
                  onClick={() => setActiveSection(Categories.BLOG)}
                >
                  <u>Blog</u>
                </button>
              </h2>
            </div>
            <div>
              <h2>
                <button
                  type="button"
                  className={activeSection === Categories.SEMINARE ? 'active' : ''}
                  onClick={() => setActiveSection(Categories.SEMINARE)}
                >
                  <u>Seminare / Workshops</u>
                </button>
              </h2>
            </div>
            <div>
              <h2>
                <button
                  type="button"
                  className={activeSection === Categories.YOGA ? 'active' : ''}
                  onClick={() => setActiveSection(Categories.YOGA)}
                >
                  <u>Yoga</u>
                </button>
              </h2>
            </div>
          </div>
        </>
      )}
      <AktuellesArtikels
        searchQuery={searchQuery}
        activeSection={activeSection}
        aktuellesArtikels={data.allContentfulAktuellesArtikel.edges}
      />
      <br />
      <br />
      <br />
    </IndexLayout>
  )
}

// to add back in later when there is at least one of them:
// seminareWorkshopDateStart
// seminareWorkshopDateEnd

export const pageQuery = graphql`
  {
    allContentfulAktuellesArtikel {
      edges {
        node {
          category
          tags
          title
          body {
            childMarkdownRemark {
              html
            }
          }
          updatedAt
        }
      }
    }
  }
`
