import * as React from 'react'
import 'moment/locale/de-ch'
import moment from 'moment'
import Categories from '../enums/Categories'
import IAktuellesArtikel from '../interfaces/IAktuellesArtikel'

interface AktuellesArtikelsProps {
  searchQuery: string
  activeSection: Categories
  aktuellesArtikels: Array<IAktuellesArtikel>
}

export default function AktuellesArtikels(props: AktuellesArtikelsProps) {
  const { searchQuery, activeSection, aktuellesArtikels } = props

  const filterByCategory = (aktuellesArtikel: IAktuellesArtikel) => {
    if (aktuellesArtikel.node.category && aktuellesArtikel.node.category.length > 0) {
      return aktuellesArtikel.node.category[0] === activeSection
    }
    return false
  }

  const filterByQuery = (aktuellesArtikel: IAktuellesArtikel) => {
    if (searchQuery === '') {
      return true
    }
    return (
      (aktuellesArtikel.node.tags &&
        aktuellesArtikel.node.tags.length > 0 &&
        aktuellesArtikel.node.tags
          .join(' ')
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (aktuellesArtikel.node.category &&
        aktuellesArtikel.node.category.length > 0 &&
        aktuellesArtikel.node.category
          .join(' ')
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      aktuellesArtikel.node.body.childMarkdownRemark.html.toLowerCase().includes(searchQuery.toLowerCase()) ||
      aktuellesArtikel.node.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }

  // apply proper filter
  const artikels = aktuellesArtikels.filter(searchQuery !== '' ? filterByQuery : filterByCategory)

  if (artikels.length === 0) {
    return (
      <p>
        Keine Ergebnisse gefunden!{' '}
        <span role="img" aria-label="Trauriges Gesicht Emoji">
          😞
        </span>
      </p>
    )
  }

  // normal results render
  return (
    <>
      {artikels.map((aktuellesArtikel, index) => {
        const {
          tags,
          title,
          body,
          seminareWorkshopDateStart,
          seminareWorkshopDateEnd,
          updatedAt,
          publishDate,
          foto
        } = aktuellesArtikel.node
        return (
          <div key={index}>
            {foto && <img alt={title} srcSet={foto.sizes.srcSet} />}
            <h2>{title}</h2>
            {tags && <p>{`#${tags.join(', #')}`}</p>}
            {seminareWorkshopDateStart && <p>Anfang {moment(seminareWorkshopDateStart).format('LLL')}</p>}
            {seminareWorkshopDateEnd && <p>Ende {moment(seminareWorkshopDateEnd).format('LLL')}</p>}
            <div
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html
              }}
            />
            {publishDate && <i className="small">Veröffentlicht {moment(publishDate).fromNow()}</i>}
            {updatedAt && <i className="small">Aktualisiert {moment(updatedAt).fromNow()}</i>}
          </div>
        )
      })}
    </>
  )
}
