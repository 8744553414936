import * as React from 'react'
import { useEffect, useState } from 'react'
import useDebounce from '../hooks/useDebounce'

export interface SearchProps {
  onChangeSearchQuery: (searchQuery: string) => void
}

export default function SearchInput(props: SearchProps) {
  const [searchQuery, setSearchQuery] = useState<string | undefined>()
  const { onChangeSearchQuery } = props
  const debouncedSearchQuery = useDebounce(searchQuery, 250)

  useEffect(() => {
    if (debouncedSearchQuery !== undefined) {
      onChangeSearchQuery(debouncedSearchQuery)
    }
  }, [debouncedSearchQuery, onChangeSearchQuery])

  return (
    <div className="row">
      <input
        type="search"
        name="fancy-search"
        id="fancy-search"
        aria-label="Search"
        placeholder="Blog, Seminare, Workshops, oder Yoga durchsuchen..."
        onChange={event => setSearchQuery(event.target.value)}
      />
      <label htmlFor="fancy-search">Suchen</label>
    </div>
  )
}
